





























import { CreateCommunityViewModel } from '@/modules/community-organize/viewmodels/create-community-viewmodel'
import { DaoDetailSettingViewModel } from '@/modules/community-organize/viewmodels/dao-detail-setting-viewmodel'
import { rules } from '@/plugins/rules'
import { Observer } from 'mobx-vue'
import { Component, Inject, Provide, Ref, Vue, Watch } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'basic-information': () => import('@/modules/community-organize/components/forms/basic-information.vue'),
    'more-information': () => import('@/modules/community-organize/components/forms/more-information.vue'),
    'main-menu': () => import('@/modules/community-organize/components/forms/main-menu.vue'),
    contributor: () => import('@/modules/community-organize/components/forms/contributor.vue'),
    rule: () => import('@/modules/community-organize/components/forms/rule.vue'),
    faq: () => import('@/modules/community-organize/components/forms/faq.vue'),
    'radio-important': () => import('@/modules/community-organize/components/forms/radio-important.vue'),
    classification: () => import('@/modules/community-organize/components/forms/classification.vue'),
  },
})
export default class extends Vue {
  @Provide() vm = new CreateCommunityViewModel()
  @Inject({}) setting!: DaoDetailSettingViewModel
  focusField = ''

  @Watch('$route.params.id', { immediate: true }) editCommunityChange(val) {
    if (val) {
      this.vm.editCommunityLoadData(val)
    }
  }

  @Watch('$route.query.focusField', { immediate: true }) async onQueryChanged(focusField) {
    if (focusField) {
      if (focusField === 'rule') this.setting.changeOpenRuleDialog(true)
      else if (focusField === 'faq') this.setting.changeOpenFaqDialog(true)

      this.focusField = focusField
    }
  }
  mounted() {
    if (this.focusField) {
      setTimeout(() => {
        const element = document.getElementById(`dao-${this.focusField}`)
        if (element) {
          const top = element.offsetTop
          window.scrollTo(0, top - 50)
        }
      }, 1000)
    }
  }
}
